@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@font-face {
    font-family: Reef;
    src: url(../Assets/Reef.otf);
 }

.card-container{
    width: 100%;
    height: 200px;
    position: relative;
}

.hero-box{
    /* background-image: url('../Assets/hero.jpg'); */
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;  /* for demo purpose  */
    overflow: hidden;
}

.hero-image{
    width: 100%;
}

.profile-container{
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 80%;
    padding: 20px;
    left: 0;
    z-index: 9;
    border-radius: 20px;
    background-color: white;
}

.profile-pic{
    background-color: rebeccapurple;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin-top: -95px;
    border-style: solid;
    border-color: white;
    border-width: 3px;
}

.profile-container hr{
    margin-left: -20;
    margin-right: -20;
}

.profile-container h2{
    padding: 10px;
    font-size: xx-large;
    font-family: Roboto;
}

.profile-container h5{
    font-family: Roboto;
    color: grey;
    font-size: small;
}

.contact-button{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: x-large;
    background-color: black;
    color: white;
    border: none;
}

.social-icon{
    height: 60px;
    width: 60px;
}

.social-title{
    color: rgba(0, 0, 0, 0.672);
    font-size: medium;
    font-style: italic;
}

.social-box{
    padding-top: 5px;
    border-radius: 10px;
}

.social-item{
    padding: 10px;
    border-radius: 10px;
}

.powered-by-text{
    font-family: Reef;
}

.footer{
    padding: 10px;
}